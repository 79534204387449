.location-search-input-group .input-group-append {
  width: 40px;
  .input-group-text {
    width: 100%;
  }
}

.autocomplete-dropdown-container,
.suggestion-item {
  color: white !important;
  background-color: #42454d !important;
}

.suggestion-item--active {
  background-color: #dab14b !important;
  cursor: 'pointer';
}

.suggestion-item,
.suggestion-item--active {
  display: flex;

  span {
    padding: 8px;
  }
}
